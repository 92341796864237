import { ReactNode } from 'react'
import NotFoundNavigationColumn from './components/ErrorColumn'
import { MainLayout } from 'src/layouts'
import styles from './styles.module.scss'

type Action = {
  content: ReactNode
  handler: () => void
  key: string
}

export type ErrorPageProps = {
  actions: Action[]
  code: number
  description: ReactNode
  showImage: boolean
  title: string
}

export const ErrorPage = ({
  actions = [],
  code,
  description,
  showImage = true,
  title,
}: ErrorPageProps) => (
  <MainLayout>
    <div className={styles.container}>
      <div className={styles.message}>
        {showImage && (
          <div className={styles.square}>
            <span className={styles.code}>{code}</span>
            <span>Error</span>
          </div>
        )}
        <div className={styles.descriptionContainer}>
          <h1 className={styles.title}>{title}</h1>
          <p className={styles.description}>{description}</p>
          <div className={styles.actions}>
            {actions.map(action => (
              <button
                className={styles.actionButton}
                onClick={action.handler}
                key={action.key}
              >
                {action.content}
              </button>
            ))}
          </div>
        </div>
      </div>
      <nav className={styles.navigation}>
        <NotFoundNavigationColumn
          title='Analytical tools'
          links={[
            { key: 'Treemap', path: '/' },
            // { key: 'Liquidity Book', path: '/liquidity-book' },
            { key: 'Watchlist', path: '/watchlist' },
          ]}
        />
        <NotFoundNavigationColumn
          title='Media resources'
          links={[
            { key: 'Press releases', path: '/press-releases' },
            { key: 'Events', path: '/events' },
          ]}
        />
        <NotFoundNavigationColumn
          title='Methodology'
          links={[
            { key: 'Our calculation methods', path: '/about/methodology' },
            // { key: 'Liquidity book service', path: '/about/liquidity-book' }
          ]}
        />
      </nav>
    </div>
  </MainLayout>
)

export default ErrorPage
