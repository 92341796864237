import { memo } from 'react'
import Link from 'next/link'
import styles from './styles.module.scss'

export type ErrorColumnProps = {
  title: string
  links: { key: string; path: string }[]
}

function ErrorColumn({ title, links = [] }: ErrorColumnProps) {
  return (
    <div className={styles.column}>
      <div className={styles.title}>{title}</div>
      <ul className={styles.linksList}>
        {links.map(link => (
          <li className={styles.item} key={link.key}>
            <Link href={link.path} className={styles.link}>
              {link.key}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default memo(ErrorColumn)
