import ErrorPage from 'src/pages/ErrorPage'

export default function Error() {
  return (
    <ErrorPage
      code={404}
      description='Sorry, the page you are looking for has not been found. Try returning to the Homepage.'
      title='Page not found'
    />
  )
}
